import { LoaderFunctionArgs } from "@remix-run/node"; // or cloudflare/deno
import { ClientLoaderFunctionArgs, useParams } from "@remix-run/react";
import { Outlet } from "@remix-run/react";
import Theme from "~/components/Theme";
import { DefaultShopSlugLoaderResponse } from "./types";
import { getDefaultServerLoaderData } from "./helpers.server";
import { addIframeToBody } from "./helpers.client";
import i18next from "i18next";
import { GeneralErrorBoundary } from "~/components/GeneralErrorBoundary";
import { useEffect } from "react";
import { setFavicon, setTitle } from "~/helpers/shopData.client";

export const loader = async ({ params, request }: LoaderFunctionArgs): Promise<DefaultShopSlugLoaderResponse> => {
  return await getDefaultServerLoaderData(params, request);
};

export const clientLoader = async ({ params, serverLoader }: ClientLoaderFunctionArgs) => {
  const shopSlug = params.shopSlug || "";
  const serverLoaderData = await serverLoader<DefaultShopSlugLoaderResponse>();
  const shopData = serverLoaderData?.shopData;
  const locale = serverLoaderData?.locale;

  if (!shopData) {
    throw new Response("Not Found", { status: 404 });
  }

  // add a hidden iframe that serves 360.postco.co so that the css and js are cached in memory and disk
  addIframeToBody(shopSlug);

  const { faviconUrl, title, titleTranslations } = shopData.userInterfaceSetting;
  setFavicon(faviconUrl);
  setTitle(title, titleTranslations, locale);

  // set client locale
  i18next.changeLanguage(serverLoaderData.locale);

  return serverLoaderData;
};

clientLoader.hydrate = true;

export default function ShopSlugLayout() {
  const { shopSlug } = useParams();

  useEffect(() => {
    const currentUrl = new URL(window.location.href);
    const isEmbedded = currentUrl.searchParams.get("embed") === "true";

    // Redirect to the embedded version of the app if the embed param is true, for backwards compatibility
    if (isEmbedded && window) {
      const { host, protocol } = currentUrl;
      const hostIsLocalhost = host.includes("localhost");
      const updatedHost = hostIsLocalhost ? `embed.${host}` : `embed-${host}`;
      const localeSearchParam = currentUrl.searchParams.get("locale");
      const searchParams = localeSearchParam ? `?locale=${localeSearchParam}` : "";
      const embeddedUrl = `${protocol}//${updatedHost}/${shopSlug}${searchParams}`;

      window.location.href = embeddedUrl;
    }
  }, []);

  return (
    <Theme>
      <Outlet />
    </Theme>
  );
}

export function ErrorBoundary() {
  return <GeneralErrorBoundary />;
}
