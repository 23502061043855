import { getPublicEnv } from "~/helpers";

export function addIframeToBody(shopSlug: string) {
  // Create a regular expression to match /${shopSlug} and /${shopSlug}/
  // so that an iframe is only added to the first page
  const regex = new RegExp(`^/${shopSlug}/?$`);
  if (!regex.test(window.location.pathname)) return;

  const baseUrl = getPublicEnv("OLD_CUSTOMER_APP_URL");
  const shopUrl = `${baseUrl}/${shopSlug}?skip-reroute=1`;

  const iframe = document.createElement("iframe");
  iframe.src = shopUrl;
  iframe.className = "hidden";
  document.body.appendChild(iframe);
}
